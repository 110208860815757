import React from "react"
import * as styles from "./TrustedPartners.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const TrustedPartners = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.trusted}>
        <Container className={styles.trustedContainer}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <Row className={`gap-30 ${styles.cardWrapper}`}>
            {strapiData?.cards?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i}>
                <div className={styles.card}>
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TrustedPartners

import { Script, graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import MainLayout from "../layouts/MainLayout"
import Banner from "../components/software-rescue-page/Banner"
import Transform from "../components/software-rescue-page/Transform"
import Cause from "../components/software-rescue-page/Cause"
import Opportunity from "../components/web-application/Opportunity"
import Shape from "../components/web-application/Shape"
import Reliable from "../components/software-rescue-page/Reliable"
import TrustedPartners from "../components/software-rescue-page/TrustedPartners"
import Deliver from "../components/software-rescue-page/Deliver"
import Sets from "../components/software-rescue-page/Sets"
import Recovering from "../components/software-rescue-page/Recovering"
import WideRange from "../components/software-rescue-page/WideRange"
import WideRangeMobile from "../components/software-rescue-page/WideRangeMobile"
import DeliverMobile from "../components/software-rescue-page/DeliverMobile"
import PackedIndustry from "../components/web-application/PackedIndustry"
import Clients from "../components/web-application/ClientsWeb"
import Projects from "../components/web-application/Projects"
import ProjectsMobile from "../components/web-application/ProjectsMobile"
import Essential from "../components/software-rescue-page/Essential"
const softwareproject = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const transform = data?.strapiPage?.sections[1]
  const cause = data?.strapiPage?.sections[2]
  const dontLet = data?.strapiPage?.sections[3]
  const newLife = data?.strapiPage?.sections[4]
  const reliable = data?.strapiPage?.sections[5]
  const deliver = data?.strapiPage?.sections[6]
  const trustedPartners = data?.strapiPage?.sections[7]
  const seem = data?.strapiPage?.sections[8]
  const sets = data?.strapiPage?.sections[9]
  const recover = data?.strapiPage?.sections[10]
  const excited = data?.strapiPage?.sections[11]
  const wideRange = data?.strapiPage?.sections[12]
  const packed = data?.strapiPage?.sections[13]
  const story = data?.strapiPage?.sections[14]
  const projects = data?.strapiPage?.sections[15]
  const essential = data?.strapiPage?.sections[16]
  const faqs = data?.strapiPage?.sections[17]

  return (
    <MainLayout>
      <Banner strapiData={heroSection} />
      <Transform strapiData={transform} />
      <Cause strapiData={cause} />
      <Opportunity strapiData={dontLet} rescueOpportunity={true} />
      <Shape strapiData={newLife} rescueWeb={true} />
      <Reliable strapiData={reliable} />
      {!isMobile && !isTablet ? (
        <Deliver strapiData={deliver} />
      ) : (
        <DeliverMobile strapiData={deliver} />
      )}
      <TrustedPartners strapiData={trustedPartners} />
      <Opportunity strapiData={seem} rescueOpportunity={true} />
      <Sets strapiData={sets} />
      <Recovering strapiData={recover} />
      <Opportunity strapiData={excited} rescueOpportunity={true} />
      {!isMobile && !isTablet ? (
        <WideRange strapiData={wideRange} />
      ) : (
        <WideRangeMobile strapiData={wideRange} />
      )}
      <PackedIndustry strapiData={packed} noCode={true} />
      <Clients strapiData={story} nocodeclient={true} />
      {!isMobile && !isTablet ? (
        <Projects strapiData={projects} />
      ) : (
        <ProjectsMobile strapiData={projects} />
      )}
      <Essential strapiData={essential} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "software-project-rescue-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default softwareproject

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="robots" content="noindex, nofollow"></meta>
      <meta name="googlebot" content="noindex, nofollow"></meta>
    </>
  )
}

// extracted by mini-css-extract-plugin
export var blogDesc = "Essential-module--blogDesc--e45a7";
export var blogimg = "Essential-module--blogimg--fb33f";
export var card = "Essential-module--card--62962";
export var cardWrapper = "Essential-module--cardWrapper--1bd8e";
export var category = "Essential-module--category--81759";
export var essentialRescue = "Essential-module--essentialRescue--4bbd0";
export var heading = "Essential-module--heading--2f708";
export var minRead = "Essential-module--minRead--b2999";
export var topDetails = "Essential-module--topDetails--e443e";
export var writer = "Essential-module--writer--a537c";
export var writerName = "Essential-module--writerName--b6ea5";
// extracted by mini-css-extract-plugin
export var CauseWebRescue = "Cause-module--CauseWebRescue--723cd";
export var btn = "Cause-module--btn--d5ada";
export var cards = "Cause-module--cards--68cc8";
export var customBtn = "Cause-module--customBtn--55e9e";
export var description = "Cause-module--description--abcc3";
export var hireImg = "Cause-module--hireImg--4297f";
export var hireTechnical = "Cause-module--hireTechnical--e8207";
export var subTitle = "Cause-module--subTitle--3df1d";
export var talent = "Cause-module--talent--6c6dc";
export var teamBtn = "Cause-module--teamBtn--4a894";
export var text = "Cause-module--text--788ea";
export var tick = "Cause-module--tick--90771";
// extracted by mini-css-extract-plugin
export var cardBg = "WideRange-module--cardBg--748af";
export var cards = "WideRange-module--cards--c198a";
export var cardsContentResqure = "WideRange-module--cardsContentResqure--e664f";
export var description = "WideRange-module--description--668f6";
export var heading = "WideRange-module--heading--9b532";
export var iconssCard = "WideRange-module--iconssCard--060f8";
export var nav = "WideRange-module--nav--4d77a";
export var navItem = "WideRange-module--nav-item--4ae47";
export var navbarBlock = "WideRange-module--navbarBlock--196b6";
export var tabData = "WideRange-module--tabData--f0ea5";
export var tech = "WideRange-module--tech--863ae";
export var techIcon = "WideRange-module--techIcon--6e5b8";
export var techImg = "WideRange-module--techImg--64755";
export var technologyIcon = "WideRange-module--technologyIcon--f7546";
export var wideRangeStacksResque = "WideRange-module--wideRangeStacksResque--a6fa9";
import React from "react"
import * as styles from "./Deliver.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
const Deliver = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.deliverRescue}>
        <Container>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <div
            className={styles.descriptionDeliver}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row
            className={`gap-30 justify-content-center ${styles.cardWrapper}`}
          >
            {strapiData?.cards?.map((e, i) => (
              <div key={i}>
                <div
                  className={`${i % 2 === 0 ? styles.card : styles.oddCard}`}
                >
                  <div
                    className={
                      i === 0
                        ? styles.firstProcess
                        : i === 1
                        ? styles.secondProcess
                        : i === 2
                        ? styles.thirdProcess
                        : i === 3
                        ? styles.fourthProcess
                        : i === 4
                        ? styles.fifthProcess
                        : ""
                    }
                  >
                    <lottie-player
                      autoplay
                      loop
                      src={e?.image1[0]?.localFile?.publicURL}
                      style={{
                        height: "80px",
                        width: "80px",
                        margin: "0 auto",
                      }}
                    />
                  </div>
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                  <span
                    className={
                      i === 0
                        ? styles.firststep
                        : i === 1
                        ? styles.secondstep
                        : i === 2
                        ? styles.thirdstep
                        : i === 3
                        ? styles.fourthstep
                        : i === 4
                        ? styles.fifthstep
                        : ""
                    }
                  >
                    {` Step 0${i + 1}`}
                  </span>
                </div>
              </div>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Deliver

// extracted by mini-css-extract-plugin
export var btn = "Recovering-module--btn--852ee";
export var cards = "Recovering-module--cards--7c000";
export var customBtn = "Recovering-module--customBtn--a807b";
export var description = "Recovering-module--description--f1bfb";
export var hireImg = "Recovering-module--hireImg--d1ce3";
export var hireTechnical = "Recovering-module--hireTechnical--9b1cc";
export var subTitle = "Recovering-module--subTitle--3f7c8";
export var talent = "Recovering-module--talent--395d2";
export var teamBtn = "Recovering-module--teamBtn--481bf";
export var text = "Recovering-module--text--14add";
export var tick = "Recovering-module--tick--a1e71";
export var whyChooseRecoverRescue = "Recovering-module--whyChooseRecoverRescue--b0359";
// extracted by mini-css-extract-plugin
export var btn = "Reliable-module--btn--a3f43";
export var cards = "Reliable-module--cards--c9886";
export var customBtn = "Reliable-module--customBtn--ff35a";
export var description = "Reliable-module--description--c53d8";
export var hireImg = "Reliable-module--hireImg--3b151";
export var hireTechnical = "Reliable-module--hireTechnical--53e90";
export var reliableRescue = "Reliable-module--reliableRescue--3b3f1";
export var subTitle = "Reliable-module--subTitle--d1535";
export var talent = "Reliable-module--talent--7ad01";
export var teamBtn = "Reliable-module--teamBtn--a6ba4";
export var text = "Reliable-module--text--24105";
export var tick = "Reliable-module--tick--0cf95";
// extracted by mini-css-extract-plugin
export var accImg = "Sets-module--accImg--60925";
export var approachImg = "Sets-module--approachImg--a367e";
export var bannerBtnRescue = "Sets-module--bannerBtnRescue--033c1";
export var btn_white2_border_Rescue_btn = "Sets-module--btn_white2_border_Rescue_btn--18177";
export var description = "Sets-module--description--0391c";
export var head = "Sets-module--head--ed211";
export var heading = "Sets-module--heading--37c54";
export var nextGenRight = "Sets-module--nextGenRight--cc5ac";
export var quesColor = "Sets-module--quesColor--7e8d4";
export var quesColorSelected = "Sets-module--quesColorSelected--bb520";
export var setsRescue = "Sets-module--setsRescue--50228";
export var someColor = "Sets-module--someColor--e03af";
export var textColor = "Sets-module--textColor--633e4";
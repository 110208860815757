import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import bannerTick from "../../images/hireGolang/bannerIcon.svg"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.Resucebanner}>
      <Container>
        <Row className="align-items-center">
          <Col xl={6} lg={12}>
            <div>
              <div className={styles.heading}>
                <h1
                  className={styles.bannerHeading}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
              </div>
              <div className={styles.cards}>
                {strapiData?.cards &&
                  strapiData?.cards?.map((item, index) => (
                    <div className={styles.talent} key={index}>
                      <img
                        className={styles.tick}
                        src={bannerTick}
                        alt={item?.title}
                        decoding="async"
                        loading="lazy"
                      />
                      <div className={styles.text}>
                        <p>{item?.title}</p>
                      </div>
                    </div>
                  ))}
              </div>
              <div className={`${styles.bannerBtn}`}>
                {strapiData?.buttons[0] && (
                  <Link
                    to={strapiData?.buttons[0]?.url}
                    className={styles.btn_white2_border}
                  >
                    {strapiData?.buttons[0]?.title}
                  </Link>
                )}
              </div>
            </div>
          </Col>
          <Col xl={6} lg={12} className={styles.imgCol}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
                c
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.title}
                decoding="async"
                loading="lazy"
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner

// extracted by mini-css-extract-plugin
export var cardBg = "WideRangeMobile-module--cardBg--9fc64";
export var cards = "WideRangeMobile-module--cards--ebcb7";
export var cardsContent = "WideRangeMobile-module--cardsContent--f104d";
export var description = "WideRangeMobile-module--description--be910";
export var heading = "WideRangeMobile-module--heading--34b70";
export var iconssCard = "WideRangeMobile-module--iconssCard--733b3";
export var nav = "WideRangeMobile-module--nav--0d0ab";
export var navItem = "WideRangeMobile-module--nav-item--f5f9e";
export var navbarBlock = "WideRangeMobile-module--navbarBlock--af3a0";
export var tabData = "WideRangeMobile-module--tabData--a4645";
export var tech = "WideRangeMobile-module--tech--249bf";
export var techIcon = "WideRangeMobile-module--techIcon--4a046";
export var techImg = "WideRangeMobile-module--techImg--d3c28";
export var technologyIcon = "WideRangeMobile-module--technologyIcon--36517";
export var wideRangeTechMobile = "WideRangeMobile-module--wideRangeTechMobile--8278f";
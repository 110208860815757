import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./WideRange.scss"
import * as styles from "./WideRange.module.scss"

const data = [
  {
    title: "Languages",
    tech: [
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_3d6f60b621.svg",
      },
      {
        name: ".NET",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dot_net_8b99ab03a0.svg",
      },
      {
        name: "JavaScript",
        icon: "https://invozone-backend.s3.amazonaws.com/javascript_1d5a458fb2.svg",
      },
      {
        name: "PHP",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_0320a2249c.svg",
      },
      {
        name: "Ruby",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ruby_8beecffde5.svg",
      },
      {
        name: "HTML5",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html5_bf38e2c0fb.svg",
      },
      {
        name: "Angular",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_4cc33f7535.svg",
      },
      {
        name: "Vue.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_15825e0ee8.svg",
      },
      {
        name: "React",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_native_fe007cea1b.svg",
      },
      {
        name: "Node.js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_c91f818b8c.svg",
      },
    ],
  },
  {
    title: "Database",
    tech: [
      {
        name: "SQL Server",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/microsoft_sql_server_4ccf8cd4ab.svg",
      },
      {
        name: "PostgreSQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_060b90b71a.svg",
      },
      {
        name: "MySQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_4bb3594541.svg",
      },
      {
        name: "MongoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_68e42fe460.svg",
      },
      {
        name: "SSAS, SSIS, SSRS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/microsoft_sql_server_4ccf8cd4ab.svg",
      },
      {
        name: "Power BI",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/power_bi_56516297aa.svg",
      },
    ],
  },
  {
    title: "Mobile Languages",
    tech: [
      {
        name: "iOS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ios_a2aa7969f8.svg",
      },
      {
        name: "Android",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_3f1ae7d2a8.svg",
      },
      {
        name: "Objective-C",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/objective_c_793093906b.svg",
      },
      {
        name: "Swift",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/swift_cfea240535.svg",
      },
      {
        name: "React Native",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_native_fe007cea1b.svg",
      },
      {
        name: "Flutter",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flutter_8a3e9944e7.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kotlin_1ca18eb589.svg",
      },
      {
        name: "Xamarin",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/xamarin_b907b36b79.svg",
      },
    ],
  },
  {
    title: "Clouds",
    tech: [
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_d4c19ca439.svg",
      },
      {
        name: "Microsoft Azure",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/microsoft_azure_01ca1995c8.svg",
      },
    ],
  },
]

const WideRange = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="wideRangeTechStacks">
      <div className={styles.wideRangeStacksResque}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentResqure}>
              <div>
                <Nav variant="pills" className={`wideRangeTechStacks`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default WideRange

// extracted by mini-css-extract-plugin
export var card = "Deliver-module--card--3250b";
export var cardWrapper = "Deliver-module--cardWrapper--015b8";
export var deliverRescue = "Deliver-module--deliverRescue--d5c91";
export var descriptionDeliver = "Deliver-module--descriptionDeliver--edcd3";
export var fifthProcess = "Deliver-module--fifthProcess--45f6b";
export var fifthstep = "Deliver-module--fifthstep--ee52f";
export var firstProcess = "Deliver-module--firstProcess--5b856";
export var firststep = "Deliver-module--firststep--1aa11";
export var fourthProcess = "Deliver-module--fourthProcess--9e424";
export var fourthstep = "Deliver-module--fourthstep--6a92d";
export var oddCard = "Deliver-module--oddCard--1ed4a";
export var secondProcess = "Deliver-module--secondProcess--712bf";
export var secondstep = "Deliver-module--secondstep--0dceb";
export var thirdProcess = "Deliver-module--thirdProcess--5e8d0";
export var thirdstep = "Deliver-module--thirdstep--f2611";
import React from "react"
import * as styles from "./Transform.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Transform = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div>
        <Container className={styles.transformRescue}>
          <Row>
            <Col>
              <h2
                className={styles.headingTransform}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div
                className={styles.descriptionTransform}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Transform

// extracted by mini-css-extract-plugin
export var Resucebanner = "Banner-module--Resucebanner--e8fdc";
export var bannerBtn = "Banner-module--bannerBtn--33fc0";
export var bannerHeading = "Banner-module--bannerHeading--87715";
export var btn_white2_border = "Banner-module--btn_white2_border--f2036";
export var cards = "Banner-module--cards--b7e8c";
export var head = "Banner-module--head--17816";
export var heading = "Banner-module--heading--c98b9";
export var imgCol = "Banner-module--imgCol--05982";
export var talent = "Banner-module--talent--a446a";
export var text = "Banner-module--text--5f30f";
export var tick = "Banner-module--tick--3d666";